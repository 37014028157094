.container {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%)
}

.row {
    margin: 0;
}

.category-button {
    background-color: #f5f5f5;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px;
}

.button-group {
    display: flex;
    justify-content: flex-end;
}

.button-item {
    background-color: #007bff;
    border-color: #007bff;
    margin-right: 5px;
}

.button-item:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.button-item:focus, .button-item:active {
    background-color: #0069d9;
    border-color: #0062cc;
}

.button-item:disabled {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.button-item:disabled:focus, .button-item:disabled:active {
    background-color: #e9ecef;
    border-color: #e9ecef;
}
